import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';

@Injectable({
  providedIn: 'root'
})

export class DynamicCredentialService {

  constructor(private http: HttpBaseService) { }

  getDynamicCredential(status: string, offset?: any, limit?: any) {

    offset = offset ? '?offset=' + offset : '?offset=0';
    limit = limit ? '&limit=' + limit : '&limit=0';
    status = status ? '&status='+ status: '';
    return this.http.get('dynamicCredential/global', {offset, limit, status});
  }

  getFirmDynamicCredential(firmId: string, offset?: any, limit?: any) {

    offset = offset ? '?offset=' + offset : '?offset=0';
    limit = limit ? '&limit=' + limit : '&limit=0';

    return this.http.get('dynamicCredential/firm/'+firmId, {offset, limit});
  }

  getSingleDynamicCredential(id: string) {
    return this.http.get('dynamicCredential/' + id);
  }


  createDynamicCredential(credential: any) {
    return this.http.post('dynamicCredential', credential);
  }

  updateDynamicCredential(id: string, credential: any) {
    return this.http.put('dynamicCredential/' + id, credential);
  }

  archiveDynamicCredential(id: string) {
    return this.http.put('dynamicCredential/archive/' + id);
  }

  unarchiveDynamicCredential(id: string) {
    return this.http.put('dynamicCredential/unarchive/' + id);
  }

  deleteDynamicCredential(id: string) {
    return this.http.delete('dynamicCredential/' + id);
  }

  updateStatus(id: string, body: any) {
    return this.http.put('dynamicCredential/updateStatus/' + id, body);
  }
  
  updateReviewerDynamicCredentials(payload: any) {
    return this.http.put('dynamicCredential/reviewerCredentials',payload);
  }
  
  getReviewerCredentials(id: string) {
    return this.http.get('dynamicCredential/reviewerCredentials/'+id);
  }

  getDynamicCredentialGlobalListForDropdown() {
    return this.http.get('dynamicCredential/globalListDropdown');
  }

  getCredentialListByFirmId(firmId: string){
    return this.http.get('firms/' + firmId + '/firmCredentialList');
  }

}
